/**
 *
 * User
 *
 */

import React, { useState, useEffect } from 'react';
import { Modal, Col, Row, Switch } from 'antd';
import { uniq, flatten } from 'lodash';

import { Box } from '../Box/Box';
import Button from '../TrackingComponents/Button';
import Select from '../Select/Select';
import { FormattedMessage } from 'react-intl';
import Image from '../Image/Image';
import messages from './messages';
import utilsMessages from 'utils/messages';
import styled from 'styles/styled-components';
import { User } from 'containers/UserManagementPage/types';
import translations from 'translations';
import Permissions from 'components/Permissions';
import { Group } from 'containers/GroupsPage/types';
import { convertPermissionsToPermissionGroups, PermissionGroup } from 'utils/permissionMap';

const Avatar = styled.div`
  margin-bottom: 18pt;
`;

const Side = styled(Box)`
  margin-bottom: 18pt;
`;

const GroupHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Heading = styled.h3`
  text-transform: uppercase;
  font-weight: 600;
`;

const Body = styled.div``;

const InfoContainer = styled.div`
  margin-bottom: 6pt;
`;

const Username = styled.h3`
  margin-bottom: 22pt;
  font-size: 18pt;
  text-transform: uppercase;
  font-weight: 600;
`;

const Devider = styled.div`
  width: 50px;
  height: 4px;
  background-color: #2f904f;
  border-radius: 4px;
  margin-bottom: 18pt;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4pt;
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: middle;
`;

const Label = styled.span`
  color: rgba(0, 0, 0, 0.54);
  flex: 1;
`;

const SubText = styled.p`
  color: #bfbfbf;
  font-weight: 400;
`;

const Value = styled.span`
  flex: 2;
`;

const Footer = styled.div`
  display: inline-block;
`;

interface OwnProps {
  user: User;
  userID: string;
  groups: Group[];
  onDeleteUser: () => void;
  onCreateGroup: () => void;
  onGoBack: () => void;
  onSave: (data) => void;
}

const User: React.SFC<OwnProps> = (props: OwnProps) => {
  const { user, userID } = props;
  const [status, setStatus] = useState(user.status === 'ACTIVE');
  const [selectedGroups, setSelectedGroups] = useState(user.userGroups || []);
  const [selectedPermissions, setSelectedPermissions] = useState((user.permissions || []).map((p) => p.action));
  useEffect(() => {
    setStatus(user.status === 'ACTIVE');
    setSelectedGroups(user.userGroups || []);
    setSelectedPermissions((user.permissions || []).map((p) => p.action));
  }, [user]);

  const onSave = () => {
    props.onSave({
      groups: selectedGroups.map((g) => g.groupId),
      permissions: selectedPermissions,
      status: status ? 'ACTIVE' : 'DISABLED',
    });
  };
  const onAddGroup = (value: string) => {
    if (!selectedGroups.find((g) => g.groupId === value)) {
      const selectedGroup = props.groups.find((g) => g.groupId === value);
      if (selectedGroup) {
        setSelectedGroups([...selectedGroups, selectedGroup]);
      }
    }
  };
  const onAddPermissions = (permissions) => {
    setSelectedPermissions([...selectedPermissions, ...permissions]);
  };
  const onRemovePermissions = (permissions) => {
    if (!permissions.length) {
      return;
    }
    const permissionGroups = convertPermissionsToPermissionGroups(selectedPermissions);

    const permissionGroupsResult: PermissionGroup[] = [];
    for (const permissionGroup of permissionGroups) {
      let permissionCounter = 0;
      for (const permission of permissionGroup.permissions) {
        if (permissions.indexOf(permission) > -1) {
          permissionCounter += 1;
        }
      }
      if (permissionCounter !== permissions.length) {
        permissionGroupsResult.push(permissionGroup);
      }
    }
    let permisisonsResult: string[] = [];
    for (const permissionGroup of permissionGroupsResult) {
      permisisonsResult = permisisonsResult.concat(permissionGroup.permissions);
    }

    setSelectedPermissions(uniq(permisisonsResult));
  };
  const onRemoveGroup = (groupId: string) => () => {
    setSelectedGroups(selectedGroups.filter((g) => g.groupId !== groupId));
  };
  const onOpenConfirmModal = () => {
    Modal.confirm({
      autoFocusButton: null,
      okButtonProps: {
        danger: true,
      },
      title: translations(messages.deleteUserMessage),
      okText: translations(utilsMessages.yes),
      cancelText: translations(utilsMessages.no),
      onOk: () => {
        props.onDeleteUser();
      },
    });
  };

  const selectedGroupPermissions = flatten(
    selectedGroups.reduce((result, group: Group) => {
      return [...result, ...group.permissions];
    }, []),
  );

  return (
    <>
      <Row gutter={8}>
        <Col lg={7} xs={24}>
          <Side>
            <Avatar>
              <Image src={user.imageUrl} />
            </Avatar>
            <InfoContainer>
              <Username>{user.name || (user.email && user.email.split('@')[0])}</Username>
            </InfoContainer>
            <Devider />
            <InfoContainer>
              <Info>
                <Label>
                  <FormattedMessage {...utilsMessages.phone} />:
                </Label>
                <Value>{user.telephone}</Value>
              </Info>
              <Info>
                <Label>Email:</Label>
                <Value>{user.email}</Value>
              </Info>
            </InfoContainer>
          </Side>
          {userID !== user.id && (
            <Footer>
              <Button type="danger" ghost block onClick={onOpenConfirmModal}>
                <FormattedMessage {...messages.deleteUser} />
              </Button>
            </Footer>
          )}
        </Col>
        <Col lg={17} xs={24}>
          <Body>
            <Box>
              <GroupHeading>
                <Heading>
                  <FormattedMessage {...messages.status} />
                </Heading>
              </GroupHeading>
              <ActionItem>
                {status ? <FormattedMessage {...messages.active} /> : <FormattedMessage {...messages.inactive} />}
                <Switch checked={status} onChange={() => setStatus(!status)} />
              </ActionItem>
              <GroupHeading>
                <div>
                  <Heading>
                    <FormattedMessage {...messages.groups} />
                  </Heading>
                  <SubText>
                    <FormattedMessage {...messages.groupDes} />
                  </SubText>
                </div>
                <Button size="small" type="primary" ghost onClick={props.onCreateGroup}>
                  <FormattedMessage {...messages.createGroup} />
                </Button>
              </GroupHeading>
              <Row>
                <Select
                  placeholder={translations(messages.selectGroup)}
                  items={(props.groups || []).map((group: any) => ({ value: group.groupId, label: group.name }))}
                  style={{ width: '100%' }}
                  value={undefined}
                  onChange={onAddGroup}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  size="large"
                />
              </Row>
              {selectedGroups.map((group) => (
                <div key={group.groupId} style={{ paddingTop: 8, paddingBottom: 8 }}>
                  <Row key={group.name} justify="space-between" align="middle">
                    <span>{group.name}</span>
                    <Button size="small" onClick={onRemoveGroup(group.groupId)}>
                      <FormattedMessage {...utilsMessages.remove} />
                    </Button>
                  </Row>
                  <SubText>
                    {convertPermissionsToPermissionGroups((group.permissions || []).map((p) => p.action))
                      .map((pg) => pg.title)
                      .join(', ')}
                  </SubText>
                </div>
              ))}
            </Box>
            <Box>
              <GroupHeading>
                <Heading>
                  <FormattedMessage {...messages.permissions} />
                </Heading>
              </GroupHeading>
              <Permissions
                selectedPermissions={selectedPermissions}
                onAddPermissions={onAddPermissions}
                onRemovePermissions={onRemovePermissions}
                selectedGroupPermissions={selectedGroupPermissions}
              />
            </Box>
          </Body>
        </Col>
      </Row>
      <Row justify="end">
        <Button
          trackingCategory="User Details | Permission | Button"
          trackingAction="Back to list"
          style={{ marginRight: 20 }}
          onClick={props.onGoBack}
        >
          <FormattedMessage {...messages.backToList} />
        </Button>
        <Button
          trackingCategory="User Details | Permission | Button"
          trackingAction="Save"
          type="primary"
          onClick={onSave}
        >
          <FormattedMessage {...utilsMessages.save} />
        </Button>
      </Row>
    </>
  );
};

export default User;
