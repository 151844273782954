/*
 *
 * UserDetailsPage reducer
 *
 */

import { ContainerActions, ContainerState } from './types';

import ActionTypes from './constants';

export const initialState: ContainerState = {
  user: {
    loading: true,
  },
};

function userDetailsPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };
    case ActionTypes.SAVE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };
    case ActionTypes.DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export default userDetailsPageReducer;
