import { addMembersGroup, deleteUser, getCompanyUser, removeMembersGroup, updateUser } from 'utils/apollo';
import { all, call, fork, put, select, take } from 'redux-saga/effects';
import { createMatchSelector, goBack, push } from 'connected-react-router';
import { saveUser as saveUserActions, user as userActions } from './actions';

import ActionTypes from './constants';
import { message } from 'antd';
import messages from './messages';
import { selectBuyer } from 'containers/MainLayout/selectors';
import { selectUser } from './selectors';
import translations from 'translations';
import utilsMessages from 'utils/messages';

function* initDataFlow() {
  yield put(userActions.request());
  const route = yield select(createMatchSelector('/user-details/:slug'));

  const response = yield call(getCompanyUser, {
    id: route.params.slug,
  });

  if (response.errors) {
    yield put(userActions.failure(response.errors));
    message.error(translations(messages.loadUserFailed));
    yield put(push('/user-management'));
  } else {
    yield put(userActions.success(response));
  }
}

function* deleteUserFlow() {
  while (true) {
    yield take(ActionTypes.DELETE_USER_REQUEST);
    const route = yield select(createMatchSelector('/user-details/:slug'));
    const response = yield call(deleteUser, {
      id: route.params.slug,
    });
    if (!response.errors) {
      yield put(goBack());
      message.success(utilsMessages.deleteSuccess);
    } else {
      message.error(utilsMessages.deleteFailed);
    }
  }
}

function* saveUserFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SAVE_USER_REQUEST);

    const user = yield select(selectUser());
    const buyer = yield select(selectBuyer());
    const route = yield select(createMatchSelector('/user-details/:slug'));
    yield all(
      user.userGroups.map(group =>
        call(removeMembersGroup, {
          input: {
            groupId: group.groupId,
            memberIds: [user.id],
          },
        }),
      ),
    );
    yield all(
      payload.groups.map(groupId =>
        call(addMembersGroup, {
          input: {
            groupId: groupId,
            memberIds: [user.id],
          },
        }),
      ),
    );

    const response = yield call(updateUser, {
      input: {
        id: route.params.slug,
        status: payload.status,
        permissions: payload.permissions.map(p => ({ action: p, scope: { type: 'BUYER', id: buyer.id } })),
      },
    });

    if (!response.errors) {
      yield put(saveUserActions.success(response.user));
      message.success(translations(utilsMessages.saveSuccess));
      yield put(goBack());
    } else {
      yield put(saveUserActions.failure(response.errors));
      message.error(translations(utilsMessages.saveFailed));
    }
  }
}

// Individual exports for testing
export default function* userDetailsPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(initDataFlow);
  yield fork(deleteUserFlow);
  yield fork(saveUserFlow);
}
