/**
 *
 * Permissions
 *
 */

import React, { Fragment, useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Checkbox as ACheckbox } from 'antd';
import { permissionGroups, getPermissionsActive } from 'utils/permissionMap';
import styled from 'styles/styled-components';

interface OwnProps {
  selectedPermissions: string[];
  isSystemCreated?: boolean;
  onAddPermissions: (data) => void;
  onRemovePermissions: (data) => void;
  selectedGroupPermissions: object[];
}

interface PermissionType {
  action: string;
}

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: middle;
`;

const SubText = styled.p`
  color: #bfbfbf;
  font-weight: 400;
`;

const Checkbox = styled(ACheckbox)`
  .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #52c41a;
    border-color: #52c41a;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 1px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1.6) translate(-50%, -50%);
    margin-left: 3px;
  }
`;

const Permissions: React.SFC<OwnProps> = (props: OwnProps) => {
  const { selectedGroupPermissions, isSystemCreated } = props;
  const [selectedPermissions, setSelectedPermissions] = useState(props.selectedPermissions);
  useEffect(() => setSelectedPermissions(props.selectedPermissions), [props.selectedPermissions]);
  const onChangePermission = (permissions) => (event) => {
    if (event.target.checked) {
      props.onAddPermissions(permissions);
    } else {
      props.onRemovePermissions(permissions);
    }
  };

  const detectGroupPermissions = (permissions) =>
    permissions
      .map((p) => selectedGroupPermissions.filter((gp: PermissionType) => gp.action === p).length)
      .every((i) => i > 0);

  return (
    <Fragment>
      {permissionGroups.map((item, index) => (
        <ActionItem key={index.toString()}>
          <label htmlFor={`permission_${index}`}>
            <span>{item.title}</span>
            <SubText>{item.description}</SubText>
          </label>
          {isSystemCreated || detectGroupPermissions(item.permissions) ? (
            <CheckOutlined style={{ fontSize: '28px', color: '#52C41A' }} />
          ) : (
            <Checkbox
              id={`permission_${index}`}
              checked={getPermissionsActive(item.permissions, selectedPermissions)}
              onChange={onChangePermission(item.permissions)}
            />
          )}
        </ActionItem>
      ))}
    </Fragment>
  );
};

export default Permissions;
