/**
 *
 * UserDetailsPage
 *
 */

import { Dispatch, compose } from 'redux';
import React, { Fragment } from 'react';
import { deleteUser as deleteUserActions, saveUser as saveUserActions } from './actions';
import { goBack, push } from 'connected-react-router';

import { FormattedMessage } from 'react-intl';
import PageHeader from 'components/PageHeader/PageHeader';
import { RootState } from './types';
import User from 'components/User';
import { connect } from 'react-redux';
// import { FormattedMessage } from "react-intl";
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import selectUserDetailsPage from './selectors';
import { selectUserGroups, selectUser } from 'containers/MainLayout/selectors';

// import messages from "./messages";

interface OwnProps {
  user: any;
  userdetailspage: any;
  userGroups: [];
}

interface StateProps {}

interface DispatchProps {
  goBack: () => void;
  deleteUser: () => void;
  gotoCreateGroup: () => void;
  save: (data) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class UserDetailsPage extends React.Component<Props> {
  public render() {
    return (
      <Fragment>
        <PageHeader>
          <FormattedMessage {...messages.header} />
        </PageHeader>
        <User
          user={this.props.userdetailspage.user}
          userID={this.props.user.id}
          groups={this.props.userGroups}
          onDeleteUser={this.props.deleteUser}
          onCreateGroup={this.props.gotoCreateGroup}
          onSave={this.props.save}
          onGoBack={this.props.goBack}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  userdetailspage: selectUserDetailsPage(),
  userGroups: selectUserGroups(),
  user: selectUser(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    goBack: () => dispatch(goBack()),
    deleteUser: () => dispatch(deleteUserActions.request()),
    gotoCreateGroup: () => dispatch(push('/groups/0')),
    save: data => dispatch(saveUserActions.request(data)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'userDetailsPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'userDetailsPage', saga: saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(UserDetailsPage);
