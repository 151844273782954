import { ApplicationRootState } from 'types';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the userDetailsPage state domain
 */

const selectUserDetailsPageDomain = (state: ApplicationRootState) => {
  return state ? state.userDetailsPage : initialState;
};

const selectUser = () =>
  createSelector(selectUserDetailsPageDomain, substate => {
    return (substate as any).user;
  });

/**
 * Other specific selectors
 */

/**
 * Default selector used by UserDetailsPage
 */

const selectUserDetailsPage = () =>
  createSelector(selectUserDetailsPageDomain, substate => {
    return substate;
  });

export default selectUserDetailsPage;
export { selectUserDetailsPageDomain, selectUser };
