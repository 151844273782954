/*
 *
 * UserDetailsPage actions
 *
 */

import {} from './types';

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const setActiveStatus = () => action(ActionTypes.SET_ACTIVE_STATUS);
export const setGroup = data => action(ActionTypes.SET_GROUP, data);

export const user = createAsyncAction(
  ActionTypes.FETCH_USER_REQUEST,
  ActionTypes.FETCH_USER_SUCCESS,
  ActionTypes.FETCH_USER_FAILURE,
)<void, {}, Error>();

export const saveUser = createAsyncAction(
  ActionTypes.SAVE_USER_REQUEST,
  ActionTypes.SAVE_USER_SUCCESS,
  ActionTypes.SAVE_USER_FAILURE,
)<{}, {}, Error>();

export const deleteUser = createAsyncAction(
  ActionTypes.DELETE_USER_REQUEST,
  ActionTypes.DELETE_USER_SUCCESS,
  ActionTypes.DELETE_USER_FAILURE,
)<void, {}, Error>();
