/*
 * User Messages
 *
 * This contains all the text for the User component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.User';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the User component!',
  },
  deleteUserMessage: {
    id: `${scope}.deleteUserMessage`,
    defaultMessage: 'Bạn có chắc muốn xoá đồng nghiệp này?',
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Hoạt động',
  },
  inactive: {
    id: `${scope}.inactive`,
    defaultMessage: 'Không hoạt động',
  },
  deleted: {
    id: `${scope}.deleted`,
    defaultMessage: 'Đã xoá',
  },
  deleteUser: {
    id: `${scope}.deleteUser`,
    defaultMessage: 'Xoá đồng nghiệp',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Trạng thái',
  },
  groups: {
    id: `${scope}.groups`,
    defaultMessage: 'Nhóm',
  },
  permissions: {
    id: `${scope}.permissions`,
    defaultMessage: 'Quyền',
  },
  createGroup: {
    id: `${scope}.createGroup`,
    defaultMessage: 'Tạo nhóm',
  },
  createOrder: {
    id: `${scope}.createOrder`,
    defaultMessage: 'Tạo đơn hàng',
  },
  selectGroup: {
    id: `${scope}.selectGroup`,
    defaultMessage: 'Chọn nhóm',
  },
  groupDes: {
    id: `${scope}.groupDes`,
    defaultMessage: 'Người dùng có thể thuộc nhiều nhóm và có các quyền sử dụng đã phân theo nhóm.',
  },
  backToList: {
    id: `${scope}.backToList`,
    defaultMessage: 'Quay lại danh sách thành viên',
  },
});
