/*
 * UserDetailsPage Messages
 *
 * This contains all the text for the UserDetailsPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.UserDetailsPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Chi tiết nhân viên',
  },
  loadUserFailed: {
    id: `${scope}.loadUserFailed`,
    defaultMessage: 'Không tìm thấy nhân viên',
  },
});
