/*
 *
 * UserDetailsPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/UserDetailsPage/DEFAULT_ACTION',
  FETCH_USER_REQUEST = 'app/UserDetailsPage/FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS = 'app/UserDetailsPage/FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE = 'app/UserDetailsPage/FETCH_USER_FAILURE',
  SAVE_USER_REQUEST = 'app/UserDetailsPage/SAVE_USER_REQUEST',
  SAVE_USER_SUCCESS = 'app/UserDetailsPage/SAVE_USER_SUCCESS',
  SAVE_USER_FAILURE = 'app/UserDetailsPage/SAVE_USER_FAILURE',
  DELETE_USER_REQUEST = 'app/UserDetailsPage/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'app/UserDetailsPage/DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'app/UserDetailsPage/DELETE_USER_FAILURE',
  SET_ACTIVE_STATUS = 'app/UserDetailsPage/SET_ACTIVE_STATUS',
  SET_GROUP = 'app/UserDetailsPage/SET_GROUP',
}

export default ActionTypes;
